import { isEmpty } from 'lodash'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Page } from 'components/Page'
import { Typography, Box, Link } from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { RewardProgramDetailHeader } from './RewardProgramDetailHeader'
import { colorPrimitives } from 'components/Theme'

export const RewardProgramDetailPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId,
  })

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator />
  }

  if (
    rewardProgramQuery.isError ||
    (!rewardProgramQuery.data.corporateAccount &&
      !rewardProgramQuery.data.licensedEstablishment &&
      rewardProgramQuery.data.type !== 'Global')
  ) {
    return <p>An error occurred.</p>
  }

  const getParent = (
    type:
      | 'Global'
      | 'CorporateAccount'
      | 'LicensedEstablishment'
      | 'Organization'
      | undefined
  ) => {
    if (type === 'Global') {
      return <></>
    }

    const rewardProgram = rewardProgramQuery.data
    const parentType =
      type === 'CorporateAccount'
        ? 'Corporate Account'
        : 'Licensed Establishment'
    const parentName =
      type === 'CorporateAccount'
        ? rewardProgram.corporateAccount?.name
        : rewardProgram.licensedEstablishment?.name

    return (
      <>
        <Typography variant="h3">{parentType}</Typography>
        <div className="flex flex-col border rounded pt-2 px-4 min-h-[118px] mt-5 mb-5 space-y-4 sm:space-y-0">
          <div className="flex flex-col min-h-[118px] justify-center space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-4 space-y-4 sm:space-y-0">
              <div>
                <Typography variant="body-3">{`${parentType} Name`}</Typography>
                <Typography variant="body-1">{parentName}</Typography>
              </div>
              {type === 'LicensedEstablishment' && (
                <div>
                  <Typography variant="body-3">License Number</Typography>
                  <Typography variant="body-1">
                    #
                    {
                      rewardProgramQuery.data.licensedEstablishment
                        ?.licenseNumber
                    }
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/RewardsProgram/${Number(rewardProgramQuery.data.id)}`}
        />
      }
    >
      {getParent(rewardProgramQuery.data.type)}

      <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center pt-4">
        <Typography variant="h3">Linked Catalogs</Typography>
      </Box>

      <div
        className="flex flex-col rounded pt-2 px-4 min-h-[118px] mt-5 mb-5 space-y-4 md:space-y-0"
        style={{
          border: !isEmpty(rewardProgramQuery.data.rewardsCatalogs)
            ? 'solid #E5E7EB'
            : '0px',
        }}
      >
        <div className="grid grid-cols-1 gap-5 space-y-1 pt-2 pb-2 ">
          {isEmpty(rewardProgramQuery.data.rewardsCatalogs) ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              bgcolor={colorPrimitives.lightGray}
              padding={{ xs: 7 }}
              marginTop={{ xs: 2 }}
            >
              <Typography
                variant="label-form"
                marginTop={{ xs: 3 }}
                color="rgba(41, 43, 38, 0.60)"
              >
                No linked catalogs
              </Typography>
            </Box>
          ) : (
            (rewardProgramQuery.data.rewardsCatalogs ?? []).map(
              (reward: components['schemas']['FullRewardsCatalogDTO']) => (
                <div key={`parent-${reward.name}`}>
                  <Link
                    component={RouterLink}
                    to={`/RewardsProgram/${rewardProgramQuery.data.id}/RewardsCatalog/${reward.id}`}
                    id={`${reward.name}`}
                    underline="hover"
                    color="text.primary"
                  >
                    <Typography variant="body-1" key={`reward-${reward.name}`}>
                      {reward.name}
                    </Typography>
                  </Link>
                </div>
              )
            )
          )}
        </div>
      </div>
    </Page>
  )
}
