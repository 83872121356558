import { Box, Button, Link, Typography } from '@mui/material'
import JJConnectLogo from 'assets/jjConnectLogo.svg'
import { CheckboxInput } from 'components/CheckBox/CheckBox'
import { colorPrimitives } from 'components/Theme'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import ErrorIcon from 'assets/errorIcon.svg'
import {
  MessageBanner,
  MessageBannerVariant,
} from 'components/MessageBanner/MessageBanner'

export const LegalDisclaimerSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const [legalDisclaimers, setLegalDisclaimers] = useState({
    termsOfService: false,
    privacyPolicy: false,
    error: '',
  })

  const PRIVACY_POLICY_URL = import.meta.env.VITE_PRIVACY_POLICY_URL
  const TERMS_OF_SERVICE_URL = import.meta.env.VITE_TERMS_OF_SERVICE_URL

  const buildErrorMessage = () => {
    const errorMessage = (missingCheck: string) =>
      `You must read and accept ${missingCheck} to continue.`
    if (!legalDisclaimers.privacyPolicy && !legalDisclaimers.termsOfService) {
      return errorMessage('both the terms of service and privacy policy')
    } else if (!legalDisclaimers.privacyPolicy) {
      return errorMessage('the privacy policy')
    } else if (!legalDisclaimers.termsOfService) {
      return errorMessage('the terms of service')
    }
    return ''
  }

  const validateAndNavigateToNextSection = async () => {
    const validationPassed =
      legalDisclaimers.privacyPolicy && legalDisclaimers.termsOfService
    if (validationPassed) {
      changeSection('emailSection')
    } else {
      setLegalDisclaimers((previous) => {
        return { ...previous, error: buildErrorMessage() }
      })
    }
  }

  return (
    <Box className="flex justify-center flex-col w-full">
      <img src={JJConnectLogo} alt="JJ Connect logo" />
      <Box className="pt-8">
        <Typography variant="h1" textAlign="center">
          Create Account
        </Typography>
      </Box>
      <Box className="py-8 flex flex-row">
        <Typography variant="body-1">
          Before creating your account, please read our&nbsp;
          <Link href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener">
            terms of service
          </Link>
          &nbsp;and&nbsp;
          <Link href={PRIVACY_POLICY_URL} target="_blank" rel="noopener">
            privacy policy
          </Link>
          .
        </Typography>
      </Box>
      <Box className="pb-8">
        <CheckboxInput
          checked={legalDisclaimers.termsOfService}
          onClick={() => {
            setLegalDisclaimers((previous) => {
              return { ...previous, termsOfService: !previous.termsOfService }
            })
          }}
          text="I've read and accept the terms of service."
        />
        <CheckboxInput
          checked={legalDisclaimers.privacyPolicy}
          onClick={() => {
            setLegalDisclaimers((previous) => {
              return { ...previous, privacyPolicy: !previous.privacyPolicy }
            })
          }}
          text="I've read and accept the privacy policy."
        />
      </Box>
      {legalDisclaimers.error && (
        <Box className="pb-8">
          <MessageBanner
            variant={MessageBannerVariant.Error}
            icon={ErrorIcon}
            message={legalDisclaimers.error}
          />
        </Box>
      )}
      <Box className="pb-8">
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          onClick={async () => {
            await validateAndNavigateToNextSection()
          }}
        >
          <Typography variant="label-CTA">Next</Typography>
        </Button>
      </Box>
      <Typography variant="label-CTA" color={colorPrimitives.redGaming}>
        <ReactRouterLink to={'/'}>
          Already have an account? Sign In
        </ReactRouterLink>
      </Typography>
    </Box>
  )
}
