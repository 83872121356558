import { Page } from 'components/Page'
import { Stack } from '@mui/material'
import { DashBoardHeader } from './DashBoard/DashBoardHeader'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { LicensesLicensedEstablishmentPage } from './LicensesLicensedEstablishment/LicensesLicensedEstablishmentPage'
import { useGetMe } from 'hooks/api/useGetMe'
import { EmptyStateHomePage } from './EmptyStateHomePage'
import { colorPrimitives } from 'components/Theme'

export const HomePage = () => {
  const meQuery = useGetMe()
  const { currentCorporateAccountId, currentCorporateAccountName } =
    useCurrentCorporateAccountStore()
  const hasNoAccess =
    !meQuery.data?.corporateAccounts ||
    meQuery.data?.corporateAccounts.length === 0

  const handleContactClick = () => {
    const url = 'https://www.jjventures.com/contact/'
    window.open(url, '_blank')
  }

  return (
    <Page
      header={
        <DashBoardHeader
          corporateAccountId={currentCorporateAccountId}
          corporateAccountName={currentCorporateAccountName}
        />
      }
      fitBody={true}
    >
      <Stack
        className="h-screen"
        sx={{ backgroundColor: colorPrimitives.lightGray }}
      >
        {hasNoAccess ? (
          <EmptyStateHomePage onContactClick={handleContactClick} />
        ) : (
          <>
            <LicensesLicensedEstablishmentPage />
          </>
        )}
      </Stack>
    </Page>
  )
}
