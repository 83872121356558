import { Box, Typography, capitalize } from '@mui/material'
import { TextField } from 'components/TextField/TextField'
import { SectionsHeader } from './SectionsHeader'
import { Controller, type SubmitHandler, useFormContext } from 'react-hook-form'
import type { SignUpFormFields } from './FormUtils/SignUpFormFields'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'
import ErrorIcon from 'assets/errorIcon.svg'
import { useCallback } from 'react'
import { usePostLicensedEstablishmentUser } from 'hooks/api/Register/usePostLicensedEstablishmentUser'
import { useSnackbar } from 'stores/useSnackbar'
import type { ApiError } from 'openapi-typescript-fetch'
import { ActivityButton } from 'components/ActivityButton/ActivityButton'

export const NameSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const formMethods = useFormContext<SignUpFormFields>()
  const {
    formState: { errors },
    handleSubmit,
  } = formMethods
  const setMessage = useSnackbar((state) => state.setMessage)

  const postLicensedEstablishmentUserMutation =
    usePostLicensedEstablishmentUser({
      onError(error: ApiError) {
        setMessage(error.data, 'error')
      },
      onSuccess() {
        changeSection('confirmationSection')
      },
    })

  const onSubmit: SubmitHandler<SignUpFormFields> = useCallback(
    (data: SignUpFormFields) => {
      postLicensedEstablishmentUserMutation.mutate(data)
    },
    [postLicensedEstablishmentUserMutation.mutate]
  )

  return (
    <Box className="flex justify-center flex-col w-full">
      <SectionsHeader
        title="Enter Your Name"
        changeSection={changeSection}
        previousSection="phoneNumberSection"
      />
      <Controller
        name="firstName"
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            name="firstName"
            placeholder="First Name"
            type="text"
            value={value}
            onBlur={onBlur}
            error={isPresent(errors.firstName)}
            onChange={(e) => {
              onChange(e)
            }}
          />
        )}
      />
      {isPresent(errors.firstName) && isPresent(errors.firstName.message) && (
        <FormFieldMessage
          message={capitalize(errors.firstName.message)}
          variant={FormFieldMessageVariant.Error}
          icon={ErrorIcon}
        />
      )}
      <Box className="py-6">
        <Controller
          name="lastName"
          render={({ field: { onBlur, onChange, value } }) => (
            <TextField
              name="Last Name"
              placeholder="Last Name"
              type="text"
              value={value}
              onBlur={onBlur}
              error={isPresent(errors.lastName)}
              onChange={(e) => {
                onChange(e)
              }}
            />
          )}
        />
        {isPresent(errors.lastName) && isPresent(errors.lastName.message) && (
          <FormFieldMessage
            message={capitalize(errors.lastName.message)}
            variant={FormFieldMessageVariant.Error}
            icon={ErrorIcon}
          />
        )}
      </Box>
      <ActivityButton
        sx={{ width: '100%' }}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        disabled={postLicensedEstablishmentUserMutation.isPending}
        active={postLicensedEstablishmentUserMutation.isPending}
      >
        <Typography variant="label-CTA">Next</Typography>
      </ActivityButton>
    </Box>
  )
}
